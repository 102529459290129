export default {
  "footer": {
    "link": {
      "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentatie"])},
      "mentions-legales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettelijke vermeldingen"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service status"])},
      "title-competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedstrijden"])},
      "title-informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie"])},
      "title-site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over"])}
    }
  },
  "navbar": {
    "circuits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rondleidingen"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvangst"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbinding"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk de resultaten"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze diensten"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschrijving"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het team"])}
  },
  "pages": {
    "home": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De meest innovatieve oplossing voor de computerverwerking van paardensportwedstrijden.\nGeen installatie, geen updates, onmiddellijk beschikbaar."])},
      "cta": {
        "link_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak mijn account aan"])}
      },
      "offer": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Een oplossing zonder installatie</strong>, zonder verplichting en zonder training, FFE en FEI goedgekeurd"])},
        "tabs": {
          "accessible": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elke organisator moet de mogelijkheid hebben om de beste tools op de markt te gebruiken om zijn concurrentie te beheersen, zonder concessies te doen aan de prijs."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaste en transparante <strong>prijzen</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Geen verplichting</strong>"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbaar <strong>onmiddellijk</strong> na registratie"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegankelijk"])}
          },
          "conforme": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We volgen dagelijks federaal nieuws om onze applicatie up-to-date te houden. Respect voor uw privacy is voor ons essentieel en daarom hechten wij er veel waarde aan om deze op ons platform te respecteren."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voldoet aan de vereisten van <strong>FFE en FEI</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voldoet aan de <strong>Algemene Verordening Gegevensbescherming</strong> (AVG)"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting van gegevens over u <strong>in Frankrijk</strong>"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meewerkend"])}
          },
          "discipline": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met ons platform beheert u de belangrijkste hippische competitiedisciplines met altijd dezelfde kwaliteit, zowel op club- als op pro-niveau."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Dressuur</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Jumping</strong>"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Eventing</strong>"])},
              "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Hunter</strong>"])},
              "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Derbycross/derby-eventing</strong>"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disciplines"])}
          },
          "ergonomie": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De analyse van een regeling kan complex zijn, maar de invoer van de resultaten niet."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatibele <strong>computer, tablet</strong> en <strong>smartphone</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Vereenvoudigde</strong> interface"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan de slag <strong>snel</strong>"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergonomisch"])}
          },
          "pratique": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergeet complexe installaties en vervelende updates en kom meteen tot de kern van de zaak. Er is geen training vereist om het platform te gaan gebruiken."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Geen installatie</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Multistation</strong>"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondersteuning <strong>7 dagen per week</strong>"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktisch"])}
          }
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat we aanbieden"])}
      },
      "partners": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ze vertrouwen ons"])}
      },
      "price": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Eén all-inclusive tarief</strong>, voor meer eenvoud"])},
        "pricing": {
          "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang tot alle functies, onbeperkt aantal gebruikers<br />Gebruikersondersteuning 7 dagen per week<br/>Geen verplichtingen"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per maand gebruik"])},
          "list": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergonomisch, compatibel met computers, tablets en smartphones<br />Eenvoudige interface en onmiddellijke bediening."])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met ons platform kunt u de belangrijkste disciplines beheren: dressuur, springconcours, eventing, jager en altijd met dezelfde kwaliteit, zowel op club- als op pro-niveau."])},
            "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen installatie, multi-station en vooral hulp 7 dagen per week tot uw beschikking."])},
            "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze prijzen zijn vast, vrijblijvend, u hoeft geen concessies te doen om uw concurrentie te verwerken."])},
            "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voldoet aan de FFE- en FEI-vereisten en aan de AVG en bovenal worden uw gegevens gehost in Frankrijk."])}
          },
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["69€"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze enkele prijs"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze prijsaanbieding"])}
      },
      "special": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van 10 tot 11 oktober 2022<br />Vind alle resultaten en schema's"])},
        "link_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventing-resultaten en schema's"])},
        "link_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "link_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontdek<br />Paarden en wedstrijden"])}
    },
    "legals": {
      "access": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker van de website horse-and-competitions.com erkent over de vaardigheden en middelen te beschikken die nodig zijn om toegang te krijgen tot en gebruik te maken van deze website. Gebruikers van de website zijn verplicht de bepalingen van de wet met betrekking tot gegevensverwerking, bestanden en vrijheden te respecteren, waarvan overtreding bestraft kan worden met strafrechtelijke sancties. Met betrekking tot de persoonlijke informatie waartoe zij toegang hebben, moeten zij zich in het bijzonder onthouden van elke verzameling, elk misbruik en, in het algemeen, van elke handeling die de privacy of de reputatie van individuen kan schaden."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegang tot de site"])}
      },
      "data": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions verbindt zich jegens haar gebruikers om uitsluitend de gegevens te verzamelen en te verwerken die nodig zijn voor haar activiteiten die in het bijzonder verband houden met het leveren van de diensten die wij aanbieden.\n<br /><br />Horses & Competitions verzamelt daarom verschillende persoonsgegevens over u. Als voorbeeld van gegevens:\n<br /><br />identificatie (achternaam, voornaam, geboortedatum, licentienummer),<br />contactgegevens (vast of mobiel telefoonnummer, e-mailadres, postadres),<br />verbinding (bijvoorbeeld IP-adres).<br />De persoonsgegevens in de databanken van Horses & Competitions kunnen rechtstreeks afkomstig zijn van haar klanten of van een federale paardensportorganisatie (FFE, FEI).<br />Met de aldus verzonden gegevens kunnen wij onze databases bijwerken en de informatie die wij over u hebben, aanvullen."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke gegevens verzamelen wij?"])}
      },
      "editing": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor elk verzoek met betrekking tot uw persoonlijke gegevens. U kunt een e-mail sturen naar de Functionaris Gegevensbescherming (DPO) via het volgende adres: contacthorses-and-competitions.com\nAfhankelijk van de aard van uw verzoek zal de procedure anders zijn:\n<br /><br />Conform artikel 15 lid 3 AVG heeft u het recht om uw persoonsgegevens te raadplegen. Nadat u uw verzoek per e-mail aan de DPO heeft ingediend, worden alle informatie over u binnen 48 uur als bijlage per e-mail in PDF-formaat naar u verzonden.<br />Conform artikel 16 AVG heeft u recht op rectificatie. Om uw verzoek te kunnen verwerken, verzoeken wij u de gewenste correcties aan te geven in uw contactmail. Zodra de wijzigingen zijn doorgevoerd, ontvangt u binnen 48 uur een bevestigingsmail.<br />Conform artikel 17 AVG heeft u het recht om vergeten te worden. Om uw persoonlijke gegevens te verwijderen, stuurt u uw verzoek per e-mail en vermeldt u de informatie die u wilt verwijderen. Er wordt binnen 48 uur een e-mail met het verzoek om bevestiging naar u verzonden voordat er enige handeling wordt uitgevoerd om informatie over u te vernietigen. Zodra uw bevestiging is verzameld, wordt de verwijdering uitgevoerd en wordt er een bevestigingsmail naar u verzonden.<br />In overeenstemming met artikel 18 van de AVG heeft u het recht om beperking van de verwerking te verzoeken. Om uw verzoek te kunnen verwerken, geeft u de gewenste beperkingen aan in uw contact-e-mail. Zodra de wijzigingen zijn doorgevoerd, ontvangt u binnen 48 uur een bevestigingsmail.<br />In overeenstemming met artikel 20 van de AVG heeft u het recht om de overdraagbaarheid van uw gegevens te verzoeken. Nadat u uw verzoek per e-mail aan de DPO heeft ingediend, worden alle gegevens over u binnen 48 uur als bijlage per e-mail in PDF-formaat aan u doorgegeven of aan de persoon die u ons hebt aangegeven, doorgegeven."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raadpleging, wijziging en verwijdering van uw persoonsgegevens"])}
      },
      "edition": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze site, uitgegeven door het bedrijf TIWAS COMPANY, is aangegeven bij de CNIL onder nummer 1714610.\n<br />TIWAS COMPANY SAS<br />3 rue de périgny<br />17220 Saint-Rogatien<br />Siret: 791 685 837 00019"])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editie"])}
      },
      "hosting": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina's van de site worden gehost door Amazon Web Services Company."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site-hosting"])}
      },
      "opposition": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt te allen tijde bezwaar maken tegen de verwerking voor alle of een deel van de hierboven beschreven doeleinden door een e-mail te sturen naar het volgende adres: contacthorses-and-competitions.com"])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppositie"])}
      },
      "owner": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De algemene structuur, evenals de software, teksten, bewegende of stilstaande beelden, geluid, knowhow, tekeningen, grafische afbeeldingen en elk ander element waaruit de website horse-and-competitions.com bestaat, zijn uitsluitend bestemd voor gebruik door de eigenaar. Elke gehele of gedeeltelijke weergave van deze site, op welke manier dan ook, zonder de uitdrukkelijke toestemming van de eigenaar, is verboden en zou een inbreuk vormen die bestraft kan worden door de artikelen L335-2 en volgende van het Wetboek van Intellectuele Eigendom. “Horses and Competitions” is een geregistreerd handelsmerk onder het nummer I.N.P.I 4606466.\nDe hyperlinks die in het kader van deze website zijn opgezet naar andere bronnen op het internetnetwerk kunnen niet onder de verantwoordelijkheid van de eigenaar van deze website vallen."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigendom"])}
      },
      "privacy": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Europese verordening inzake de bescherming van persoonsgegevens (AVG), die op 25 mei 2018 in werking is getreden, breidt en vergemakkelijkt de uitoefening van uw rechten uit door de transparantie van de verwerking van uw persoonsgegevens te verbeteren."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevensbescherming"])}
      },
      "profiling": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdens het surfen op de site laten internetgebruikers computersporen achter. Deze set informatie wordt verzameld via een verbinding die een cookie wordt genoemd en die echter geen persoonlijke informatie bevat.\n<br /><br />Om de ergonomie, de navigatie binnen de site, de redactionele inhoud en de service aan internetgebruikers te verbeteren, slaat de tool voor het beheren van sitestatistieken informatie op met betrekking tot het profiel van internetgebruikers: apparatuur, gebruikte browser, zoekopdrachten naar geografische herkomst, datum en tijd van verbinding, navigatie op de site, frequentie van bezoeken, enz. Deze verbindingsgegevens maken statistische extracties mogelijk en worden een jaar bewaard.\n<br /><br />Elke internetgebruiker heeft de mogelijkheid om de registratie van deze gegevens te weigeren door de configuratie van de browser van zijn computer te wijzigen, die de registratie van cookies al dan niet toestaat. U kunt ze weigeren of verwijderen zonder dat dit enige invloed heeft op uw toegang tot de pagina's van de site. Om u te verzetten tegen het opslaan van cookies of om gewaarschuwd te worden voordat u cookies accepteert, raden wij u aan de helpsectie van uw browser te lezen, waarin u de te volgen procedure wordt uitgelegd."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistische gegevens: traceerbaarheid en profilering"])}
      },
      "publication": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze site wordt uitgegeven door het bedrijf TIWAS COMPANY"])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicatie"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettelijke vermeldingen"])},
      "usage": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De geregistreerde en verwerkte gegevens worden uitsluitend gebruikt voor de volgende doeleinden en in het kader van de operatie:\n<br />de handelingen uit te voeren die nodig zijn om uw gebruikersaccount te beheren (bijvoorbeeld: gepersonaliseerde hulp, enz.),\ninformatie over het platform communiceren via e-mail en/of sms,\nBezoekstatistieken genereren om uw gebruikerservaring te verbeteren."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor welke doeleinden en om welke redenen gebruiken wij uw gegevens?"])}
      },
      "who": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De interne diensten van Horses & Competitions en de hosting-, routingproviders, drukkers en alle dienstverleners of onderaannemers waarvan de vennootschap Horses & Competitions gebruik maakt in het kader van de uitvoering van bestellingen en het verlenen van de diensten die worden aangeboden en contractueel verbonden aan Horses & Competitions evenals Horses & Competitions zullen deze informatie ontvangen. De informatie wordt 2 jaar bewaard vanaf de datum van opname."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie heeft toegang tot uw gegevens?"])}
      }
    },
    "price": {
      "cta": {
        "link_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak mijn account aan"])}
      },
      "diff": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben niets te verbergen, dus onthullen we alles aan u, zelfs voordat u zich registreert"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functievergelijking"])}
      },
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eén all-inclusive tarief voor meer eenvoud."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze prijsaanbieding"])},
      "trust": {
        "features": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwerking van resultaten"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE- en FEI-compatibel"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schemagenerator"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische timing"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veldverplichtingen, uitnodigingen, verandering van rit"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventing"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumping"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressuur"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kruis binnen"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE/FEI-resultaten exporteren"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-station"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scherm op afstand"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LED-baanscherm"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCD-scherm langs de baan"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online resultaten"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bib-generator"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingsprotocollen"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PC-compatibiliteit"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mac-compatibiliteit"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiele compatibiliteit"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-up"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische hulp"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersondersteuning"])},
          "19_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorgevuld, klaar om af te drukken"])},
          "19_arg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiketten om af te drukken en op blanco protocollen te plakken"])},
          "20_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle versies die Google Chrome ondersteunen"])},
          "22_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle versies die Google Chrome ondersteunen (exclusief timing)"])},
          "23_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replicatie van uw concurrentiegegevens op 3 datacenters in realtime"])},
          "24_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via chat 7 dagen in de week reactie binnen minder dan 1 uur van 08.00 tot 20.00 uur minder dan 12 uur buiten kantooruren"])},
          "24_arg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per email"])},
          "25_arg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaald en op afspraak"])}
        },
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neen"])},
        "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalde optie"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al 10 jaar vertrouwen steeds meer van jullie ons"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ze vertrouwen ons"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
      }
    },
    "services": {
      "autonomous": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons platform is gemaakt om zich aan te passen aan elk gebruikersniveau. Voor het gebruik ervan is geen training nodig, u bent direct operationeel en bij twijfel staat ons team altijd klaar om u op afstand bij te staan. Profiteer in volledige autonomie van het eenvoudigst te gebruiken platform."])},
        "list": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE-import in 1 klik"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het genereren van trainingsprotocollen"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generatie van slabbetjes"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereren van individuele schema's"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realtime invoer van resultaten"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdracht van FFE resulteert in 1 klik"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons platform, jij hebt de controle"])}
      },
      "challenge": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Federatie, CRE, CDE, clubgroep of organisator kunt u heel eenvoudig circuits maken met behulp van de resultaten van wedstrijden die al in ons platform zijn verwerkt.<br /><br />Met onze 100% online oplossing kunt u uw resultaten delen en in realtime klassementen voor uitdagingen aanbieden."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuitbeheer"])}
      },
      "cta": {
        "link_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak mijn account aan"])}
      },
      "equyp": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De kracht van ons netwerk tot uw dienst."])},
        "communication": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van het creëren van uw visuele identiteit tot het beheren van uw website en sociale netwerken."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mededeling"])}
        },
        "speaker": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net als een dirigent brengt de spreker uw evenement tot leven, zowel op sportief vlak als door het promoten van uw sponsoren."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spreker"])}
        },
        "sponsors": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken naar sponsors, aanmaken van bestanden, ons team staat klaar om uw evenement te verbeteren."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equyp"])},
        "video": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video is het essentiële hulpmiddel om uw imago bij het publiek en partners te promoten."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video-uitzending"])}
        }
      },
      "gestion": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We weten dat het organiseren van een wedstrijd stressvol kan zijn, je moet een groot aantal taken uitvoeren voor, tijdens en na het evenement. Delegeer het beheer van uw wedstrijdresultaten aan ons en concentreer u op uw andere taken."])},
        "list": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opstellen van startnummers, protocollen en lijsten"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genereren van individuele schema's"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realtime invoer van resultaten"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending van FFE-resultaten op D+1 na de test"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegeer het beheer aan ons"])}
      },
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitiemanagement, circuitmanagement, onze teams staan klaar om u te ondersteunen."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze diensten"])}
    },
    "team": {
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions is een team met een passie voor IT en paardrijden. Wij zetten onze expertise op het gebied van security en IT-ontwikkelingen in om u een innovatief platform te bieden.<br /><br />Sinds 2013 zet Horses & Competitions zich dagelijks in om de paardensportcompetitie te promoten en voor iedereen toegankelijk te maken. Jaar na jaar, discipline na discipline, hebben we er altijd voor gezorgd dat onze tool hetzelfde gebruiksgemak biedt."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions is een team met een passie voor IT en paardrijden"])},
      "timeline": {
        "2013-03": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toen we met dit project begonnen, wilden we een innovatieve oplossing bieden die echte oplossingen biedt. Daarom hebben we vanaf 2013 ons online platform aangeboden zonder enige installatie of verplichting, een echte uitdaging in een tijd dat 4G nog niet bestond."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maart 2013"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancering van het platform"])}
        },
        "2014-07": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze oplossing trekt steeds meer organisatoren aan die op zoek zijn naar oplossingen waarmee ze meerdere stations kunnen betreden en dus vloeiender kunnen zijn. Onze oplossing is voor de eerste keer geselecteerd om de finale van het regionale kampioenschap in Saint-Cyr-du-Doret te beheren."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli 2014"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxe"])}
        },
        "2014-08": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geconfronteerd met uw groeiende vraag, voegen we CSO-ondersteuning toe aan onze oplossing. Net als bij CCE en Dressuur is deze nieuwe discipline beschikbaar voor SHF-, Pro-, Amateur- en clubcompetities."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augustus 2014"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe discipline"])}
        },
        "2014-11": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze applicatie is officieel erkend als geschikt om te downloaden naar de systemen van de Franse Hippische Federatie voor eventing en dressuur."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 2014"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE-labeling"])}
        },
        "2015-04": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dankzij de officiële erkenning van de kwaliteit van onze oplossing en het implementatiegemak ervan konden we het vertrouwen winnen van Tartas en het Grand National-team om de CCE-fase die daar dat jaar plaatsvond, te beheren."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2015"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer van de Grand National CCE"])}
        },
        "2015-09": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij Horses & Competitions houden we van uitdagingen en waarderen we uw feedback. Daarom gaan wij regelmatig de praktijk in om onze oplossing te testen voor een veeleisend publiek, bijvoorbeeld tijdens pro-dressuurevenementen op de Pôle Equestre Vendéen."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 2015"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De dressuurelite geconfronteerd met onze aanvraag"])}
        },
        "2015-10": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons succes bij het verwerken van de resultaten van de Grand National CCE-etappe in Tartas stelt ons in staat de leiding te krijgen over de Franse Amateur CCE-kampioenschappen. Naast de effectiviteit van onze oplossing worden ook ons reactievermogen en ons vermogen om oplossingen te vinden gewaardeerd."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober 2015"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer van de Franse amateurkampioenschappen"])}
        },
        "2015-11": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De France Complet Association riep ons op voor een nieuwe uitdaging: live beheer van de resultaten van de Cross Indoor in Saumur. Uitdaging die we zijn aangegaan om het gehele publiek ter plaatse en online de gebeurtenissen realtime te laten volgen."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 2015"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer van de Saumur Cross Indoor"])}
        },
        "2016-06": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op uw verzoek voegen we Hunter-ondersteuning toe aan onze oplossing. Wat CCE, CSO en Dressuur betreft, is deze nieuwe discipline beschikbaar voor SHF-, Pro-, Amateur- en clubcompetities."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni 2016"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe discipline"])}
        },
        "2016-11": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze applicatie wordt compatibel met internationale Eventing-wedstrijden"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 2016"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEI-goedkeuring"])}
        },
        "2017-03": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voortbouwend op ons wederzijds vertrouwen vertrouwt Tartas ons het beheer van zijn internationale 1- en 2-sterren CCE toe"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maart 2017"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxe"])}
        },
        "2018-10": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor het vierde jaar op rij beheren wij de resultaten van het Franse Amateur Eventing Kampioenschap"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober 2018"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonder falen"])}
        },
        "2019-04": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions verkrijgt het beheer van de etappe van de Grand National van CCE in Pompadour en die van de Master PRO"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2019"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer van de GN en de Master PRO van CCE"])}
        },
        "2020-01": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het succes van een project hangt vooral af van de mannen en vrouwen die er deel van uitmaken. Het is dan ook logisch dat Horses & Competitions zich heeft aangesloten bij het Équyp Netwerk om u een steeds completere en altijd kwalitatief hoogstaande service te bieden."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januari 2020"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerk uitrusten"])}
        },
        "2020-03": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions wil de toegang tot wedstrijden voor iedereen tijdens deze moeilijke periode bevorderen en biedt het hele jaar door het gebruik van zijn platform aan."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maart 2020"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COVID-19"])}
        },
        "2021-01": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We vereenvoudigen onze prijslijst door alle organisatoren één enkele, voordeligere prijs aan te bieden"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januari 2021"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkele prijs"])}
        },
        "2021-05": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na het een aantal maanden te hebben getest, bieden we iedereen een oplossing waarmee ze de elektronische stopwatches van MatSport en Tag Heuer kunnen gebruiken via onze online interface."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mei 2021"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatibiliteit met elektronische timingoplossingen"])}
        },
        "2022-03": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons platform is nu compatibel met FDS- en Algae-apparatuur"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maart 2022"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoeging van elektronische timingoplossingen"])}
        },
        "2022-06": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij bieden u de mogelijkheid om video-overlays te maken voor uw spring- en dressuurevenementen."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni 2022"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video-overlay"])}
        },
        "2023-02": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We hebben het bijhouden van resultaten opnieuw ontworpen om u een snellere en completere interface te bieden"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februari 2023"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisie van de ruimte voor de rijder"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We lanceerden ons online platform in 2013 zonder enige installatie of verplichting, een echte uitdaging in een tijd dat 4G nog niet bestond."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschiedenis van ons bedrijf"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons team"])}
    }
  }
}