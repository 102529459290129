<template>
  <section class="section-feature-details">
    <div class="content-wrapper">
      <div class="intro-wrapper flat">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
      <div class="row">
        <div class="column half">
          <img class="bevel" :src="require('@/assets/images/services/'+feature.imageSrc)" :alt="feature.imageAlt" />
        </div>
        <div class="column half">
          <ul v-if="feature.detailsList.length > 0">
            <li v-for="(detailsItem, indexDetailsItem) in feature.detailsList" v-bind:key="indexDetailsItem" v-html="detailsItem.body" />
          </ul>
        </div>
      </div>
      <div class="ctas" v-if="ctas.length > 0">
        <a v-for="(cta, index) in ctas" v-bind:key="index" :href="cta.href" :target="cta.target" class="button">{{cta.label}} <IconArrow v-if="cta.arrowIcon" /></a>
      </div>
    </div>
  </section>
</template>

<script>
  import IconArrow from '@/components/elements/_Arrow.vue'

  export default {
    name: 'FeatureDetails',
    components: {
      IconArrow
    },
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
      feature: {
        type: Object,
        default: () => ({ imageSrc: null, imageAlt: null, detailsList: null })
      },
      ctas: {
        type: Array,
        default: () => [{ label: null, href: null, arrowIcon: null }]
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .section-feature-details{
    .content-wrapper{
      max-width: $content_max-width_l;
      margin: 0 auto;
        
      ul{
        text-align: left;

        li{
          position: relative;
          padding-left: 30px;
          @include screens-m { padding-left: 35px; }
          @include screens-l { padding-left: 40px; }

          & + li{
            margin-top: 10px; // Add space between two successive li
            @include screens-m { margin-top: 15px; }
            @include screens-l { margin-top: 20px; }
          }

          &:before{
            content: "";
            position: absolute;
            top: 2px;
            left: 0;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background-color: $colors-blue-navy;
            overflow: hidden;

            @include screens-m { 
              width: 20px;
              height: 20px;
            }
            @include screens-l {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
</style>