export default {
  "footer": {
    "link": {
      "documentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
      "mentions-legales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Notice"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of services"])},
      "title-competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitions"])},
      "title-informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
      "title-site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])}
    }
  },
  "navbar": {
    "circuits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuits"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See results"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The team"])}
  },
  "pages": {
    "home": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The most innovative solution for the computer processing of equestrian competitions.\nWithout installation, without update, available immediately."])},
      "cta": {
        "link_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create my account"])}
      },
      "offer": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>A solution without installation</strong>, without commitment and without training, FFE and FEI approved"])},
        "tabs": {
          "accessible": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any organizer should be able to use the best tools on the market to process his competition, without compromising on price."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed and transparent <strong>prices</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>No commitment</strong>"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available <strong>immediately</strong> after registration"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessible"])}
          },
          "conforme": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We follow federal news daily to keep our application up to date. Respect for your privacy is essential for us, which is why we attach great importance to its respect on our platform."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complies with <strong>FFE and FEI</strong> requirements"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliant with <strong>General Data Protection Regulation</strong> (RGPD)"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting of data concerning you <strong>in France</strong>"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliant"])}
          },
          "discipline": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our platform allows you to manage the main disciplines of equestrian competition with always the same quality whether at club or pro level."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Dressage</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>CSO</strong>"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Eventing</strong>"])},
              "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Hunter</strong>"])},
              "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Derby Cross / Derby Eventing</strong>"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disciplines"])}
          },
          "ergonomie": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The analysis of a regulation can be complex, the capture of the results not."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatible with <strong>computer, tablet</strong> and <strong>smartphone</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Simplified</strong> interface"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting started <strong>fast</strong>"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergonomic"])}
          },
          "pratique": {
            "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget about complex installations, tedious updates, get straight to the heart of the matter. No training required to start using the platform."])},
            "details": {
              "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>No installation</strong>"])},
              "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Multipost</strong>"])},
              "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support <strong>7 days a week</strong>"])}
            },
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convenient"])}
          }
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we offer"])}
      },
      "partners": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They trust us"])}
      },
      "price": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>A single all-inclusive rate</strong>, for greater simplicity"])},
        "pricing": {
          "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to all features, unlimited users<br />User support 7 days a week<br/>No obligation"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per month of use"])},
          "list": {
            "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergonomic, compatible with computers, tablets and smartphones<br />Simple interface and immediate use."])},
            "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our platform allows you to manage the main disciplines: dressage, show jumping, CCE, Hunter and always with the same quality whether at club or pro level."])},
            "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No installation, multi-user and above all help available 7 days a week."])},
            "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our prices are fixed, without commitment, no need to make concessions to process your competition."])},
            "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complies with FFE and FEI requirements as well as RGPD and above all all your data is hosted in France."])}
          },
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 69"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Single Price"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our price offer"])}
      },
      "special": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Oct 10 to 11, 2022<br />Find all the results and schedules"])},
        "link_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results & Timetable CCE"])},
        "link_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSO Results and Schedules"])},
        "link_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter Results and Schedules"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers National (FRA)"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover<br />Horses & Competitions"])}
    },
    "legals": {
      "access": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users of the horses-and-competitions.com website acknowledge that they have the skills and means necessary to access and use this website. Users of the website are required to comply with the provisions of the law relating to computers, files and freedoms, the violation of which is punishable by criminal penalties. In particular, when it comes to personal information to which they have access, they must refrain from any collection, any misuse and in general, any act likely to infringe the privacy or reputation of individuals. ."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to the site"])}
      },
      "data": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions undertakes to its users to collect and process only the data necessary for its activities relating in particular to the provision of the services we offer.\n<br /><br />As a result, Horses & Competitions collects various personal data about you. As an example of the data:\n<br /><br />identification (name, first name, date of birth, license number),<br />contact (landline or mobile phone number, e-mail address, postal address),<br />connection (eg: IP address).<br />The personal data contained in the databases of Horses & Competitions can come directly from its customers or from a federal equestrian entity (FFE, FEI).<br />The data thus transmitted allow us to update our databases and complete the information we have about you."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What data do we collect?"])}
      },
      "editing": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For any request concerning your personal information. You can send an email to the Data Protection Officer (DPO) using the following address: contacthorses-and-competitions.com\nDepending on the nature of your request, the course of the procedure will be different:\n<br /><br />In accordance with article 15 paragraph 3 of the GDPR, you have the right to consult your personal data. After having made your request by email to the DPO, all the information concerning you will be sent to you as an attachment by email in PDF format within 48 hours.<br />In accordance with article 16 of the GDPR you have a right of rectification. For your request to be processed, please indicate the desired corrections in your contact email. Once the changes have been made, a confirmation email will be sent to you within 48 hours.<br />In accordance with article 17 of the GDPR, you have the right to be forgotten. To proceed with the deletion of your personal data, send your request by email, specifying the information you wish to withdraw. A confirmation email will be sent to you within 48 hours before proceeding with any operation to destroy your information. Once your confirmation has been collected, the deletion will be carried out and a confirmation email will be sent to you.<br />In accordance with article 18 of the GDPR, you have the right to request the restriction of processing. For your request to be processed, please indicate the desired limitations in your contact email. Once the changes have been made, a confirmation email will be sent to you within 48 hours.<br />In accordance with article 20 of the GDPR, you have the right to request the portability of your data. After having made your request by email to the DPO, all the information concerning you will be sent to you or will be sent to the person you indicate to us, as an attachment by email in PDF format within 48 hours."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation, modification and deletion of your personal data"])}
      },
      "edition": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site, published by TIWAS COMPANY, has been declared to the CNIL under number 1714610.\n<br />TIWAS COMPANY SAS<br />3 rue de Périgny<br />17220 Saint-Rogatien<br />Siret: 791 685 837 00019"])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing"])}
      },
      "hosting": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pages of the site are hosted by Amazon Web Services."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site hosting"])}
      },
      "opposition": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At any time you can oppose the processing for all or part of the purposes described above by sending an email to the following address: contacthorses-and-competitions.com"])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opposition"])}
      },
      "owner": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The general structure, as well as the software, texts, moving or still images, sound, know-how, drawings, graphics and any other element making up the horses-and-competitions.com website are for the exclusive use of its owner. Any total or partial representation of this site, by any means whatsoever, without the express authorization of the owner, is prohibited and constitutes an infringement punishable by articles L335-2 and following of the Intellectual Property Code. \"Horses and Competitions\" is a registered trademark under the number I.N.P.I 4606466.\nThe hypertext links set up within the framework of this website to other resources on the Internet can not engage the responsibility of the owner of this website."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])}
      },
      "privacy": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The European regulation for the protection of personal data (RGPD) which entered into force on May 25, 2018 extends and facilitates the exercise of your rights by improving the transparency of the processing of your personal data."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection"])}
      },
      "profiling": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When browsing the site, Internet users leave computer traces. This set of information is collected using a cookie called a cookie which does not, however, contain any personal information.\n<br /><br />In order to improve ergonomics, navigation within the site, editorial content and service to Internet users, the site statistics manager tool stores information relating to the profile of Internet users: equipment, browser used, geographical origin. requests, date and time of connection, site navigation, frequency of visits, etc. These connection data allow statistical extractions and are kept for one year.\n<br /><br />Any Internet user has the option of refusing the recording of this data by modifying the browser configuration of his computer which authorizes or not the recording of cookies. You can refuse or delete them without this having any influence on your access to the pages of the site. To oppose the registration of cookies or to be warned before accepting cookies, we recommend that you read the help section of your browser, which will tell you what to do."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistical data: traceability and profiling"])}
      },
      "publication": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is published by TIWAS COMPANY"])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Notice"])},
      "usage": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The recorded and processed data are used for the following purposes only and within the framework of the operation:\n<br />carry out the operations required to manage your user account (example: personalized assistance, etc.),\ncommunicate information relating to the platform by emails and / or sms,\ngenerate visit statistics to improve your user experience."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For what uses and for what reasons do we use your data?"])}
      },
      "who": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The internal services of Horses & Competitions and the hosting and routing providers, printers and any service providers or subcontractors to which the company Horses & Competitions calls upon in the context of the execution of orders and the provision of the services offered and contractually linked to Horses & Competitions as well as Horses & Competitions will be the recipients of this information. The information is kept for 2 years from the date of registration."])},
        "titre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who can access your data?"])}
      }
    },
    "price": {
      "cta": {
        "link_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create my account"])}
      },
      "diff": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have nothing to hide, so we'll let you know before you even register."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature comparison"])}
      },
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One all-inclusive rate for simplicity."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our price offer"])},
      "trust": {
        "features": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing of results"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE & FEI compliant"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule Generator"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic timing"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field engagements, invitations, change of riding"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventing"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumping"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressage"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunter"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derby"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Indoor"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export FFE / FEI results"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Devices"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Screen"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LED track edge screen"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCD track edge screen"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online results"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bib generator"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dressage protocols"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PC compatibility"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mac compatibility"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile compatibility"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical support"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User support"])},
          "19_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-filled, ready to print"])},
          "19_arg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels to print and stick on blank protocols"])},
          "20_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All versions supporting Google Chrome"])},
          "22_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All versions supporting Google Chrome (excluding timing)"])},
          "23_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replication of your competition data on 3 Datacenters in real time"])},
          "24_arg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By chat 7 days a week, response in less than 1 hour from 8 a.m. to 8 p.m. less than 12 hour outside working hours"])},
          "24_arg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By email"])},
          "25_arg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paying and by appointment"])}
        },
        "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Option"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For 10 years, more and more of you have trusted us"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They trust us"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])}
      }
    },
    "services": {
      "autonomous": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our platform is made to adapt to any level of user. It is not necessary to undergo training to use it, you are immediately operational and in case of doubt our team is always available to assist you remotely. Take advantage of the easiest platform to use in complete autonomy."])},
        "list": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-click FFE import"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generation of training protocols"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bib generation"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generation of individual timetables"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time entry of results"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmission of FFE results in 1 click"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our platform, you are in control"])}
      },
      "challenge": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a Federation, CRE, CDE, club group or organizer, you can easily organize circuits using the results of competitions already processed in our platform.<br /><br />Our 100% online solution allows you to pool your results and offer real-time challenge rankings."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuits management"])}
      },
      "cta": {
        "link_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create my account"])}
      },
      "equyp": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The strength of our network at your service."])},
        "communication": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From creating your visual identity to managing your website and social networks."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])}
        },
        "speaker": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like an orchestra conductor, the speaker brings your event to life both at the sporting level but also by promoting your sponsors."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speaker"])}
        },
        "sponsors": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for sponsors, creation of file, our team is there to promote your event."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsorship"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equyp"])},
        "video": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video is the essential tool to promote your image to the public and partners."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video broadcast"])}
        }
      },
      "gestion": {
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We know that running a competition can be overwhelming, you have a multitude of tasks to deal with before, during and after the event. Delegate the management of your competition results to us and concentrate on your other tasks."])},
        "list": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation of bibs, protocols and listings"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generation of individual timetables"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real-time entry of results"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmission of FFE results on D + 1 after the test"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegate the management to us"])}
      },
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition management, circuit management our teams are there to support you."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])}
    },
    "team": {
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions is a team passionate about computers and horse riding. We use our expertise in the fields of security and IT developments to offer you an innovative platform.<br /><br />Since 2013, Horses & Competitions has been committed to promoting equestrian competition and making it accessible to everyone on a daily basis. Year after year, discipline after discipline, we have always made sure to bring the same ease of use to our tool."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions is a team passionate about computers and horse riding"])},
      "timeline": {
        "2013-03": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When we started this project we wanted to offer an innovative solution that provides real solutions, which is why in 2013 we offered our online platform without any installation and commitment, a real challenge at a time when 4G did not yet exist. ."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March 2013"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch of the platform"])}
        },
        "2014-07": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our solution is attracting more and more organizers to listen to solutions allowing them to enter multiple workstations and therefore more fluid. Our solution is chosen for the first time to manage the final of the Regional Championship in Saint-Cyr-du-Doret."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July 2014"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upmarket"])}
        },
        "2014-08": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faced with your growing demand, we are adding CSO support to our solution. As for the CCE and Dressage, this new discipline is available for SHF, Pro, Amateur and club competitions."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August 2014"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New discipline"])}
        },
        "2014-11": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our application is officially recognized as being suitable for download to the systems of the French Equestrian Federation for eventing and dressage"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 2014"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFE label"])}
        },
        "2015-04": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The official recognition of the quality of our solution and its ease of implementation, allows us to gain the confidence of Tartas and the Grand National team to manage the CCE stage taking place there that year."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2015"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of the Grand National CCE"])}
        },
        "2015-09": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Horses & Competitions we like challenges and we value your feedback. This is why we regularly go out into the field to test our solution against a demanding public, for example during pro Dressage events at the Pôle Équestre Vendéen."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September 2015"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The elite of dressage in front of our application"])}
        },
        "2015-10": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our success during the processing of the results of the CCE Grand National stage in Tartas allows us to obtain the management of the French Amateur CCE championships. Beyond the effectiveness of our solution, it is our responsiveness and our ability to find solutions that are appreciated."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2015"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of the French Amateur Championships"])}
        },
        "2015-11": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The France Complet Association called on us for a new challenge: the live management of the results of the Cross Indoor of Saumur. We have taken up this challenge to allow the entire public on site and online to follow the events in real time."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 2015"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of the Cross Indoor of Saumur"])}
        },
        "2016-06": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At your request, we add Hunter support to our solution. As for the CCE, the CSO and the Dressage this new discipline is available for the SHF, Pro, Amateur and club competitions."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 2016"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New discipline"])}
        },
        "2016-11": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our application becomes compatible with the international competitions of Eventing"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November 2016"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FEI approval"])}
        },
        "2017-03": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the strength of our mutual trust, Tartas entrusts us with the management of its international 1 and 2 star CCE"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March 2017"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upmarket"])}
        },
        "2018-10": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the 4th consecutive year, we are managing the results of the French Amateur CCE Championship"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October 2018"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without fault"])}
        },
        "2019-04": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horses & Competitions obtains the management of the Grand National stage from CCE to Pompadour as well as that of the Master PRO"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April 2019"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of GN and CCE PRO Master"])}
        },
        "2020-01": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The success of a project depends above all on the men and women who make it up, so it is naturally that Horses & Competitions joined the Équyp Network to offer you an ever more comprehensive and always high-quality service."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 2020"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equyp Network"])}
        },
        "2020-03": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anxious to promote access to competitions for all in this difficult period, Horses & Competitions offers the use of its platform all year round."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March 2020"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covid 19"])}
        },
        "2021-01": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are simplifying our price list by offering a single and more advantageous price to all the organizers"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January 2021"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single price"])}
        },
        "2021-05": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After testing it for several months, we are providing everyone with a solution allowing the use of MatSport and Tag Heuer electronic stopwatches via our online interface."])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May 2021"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compatibility with electronic timing solutions"])}
        },
        "2022-03": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our platform is now compatible with FDS and Algue equipment"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March 2022"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addition of electronic timing solutions"])}
        },
        "2022-06": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you the possibility of making video overlays for your jumping and dressage"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June 2022"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video overlay"])}
        },
        "2023-02": {
          "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have redesigned the results tracking to offer you a faster and more complete interface"])},
          "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February 2023"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rider space redesign"])}
        },
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launched in 2013 our online platform without any installation and commitment, a real challenge at a time when 4G did not yet exist."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History of our company"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team"])}
    }
  }
}