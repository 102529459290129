import { createApp } from 'vue'
import MyApp from './App.vue'
import router from './router'
import i18n from './i18n'

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faBalanceScale, faBars, faBooks, faCamcorder, faCheckCircle, faCommentsAlt, faEnvelope, faHandHoldingUsd, faKeynote, faMagic, faPhoneLaptop, faTimes, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faBalanceScale)
library.add(faBars)
library.add(faBooks)
library.add(faCamcorder)
library.add(faCheckCircle)
library.add(faCommentsAlt)
library.add(faEnvelope)
library.add(faFacebookF)
library.add(faHandHoldingUsd)
library.add(faKeynote)
library.add(faInstagram)
library.add(faMagic)
library.add(faPhoneLaptop)
library.add(faTimes)
library.add(faUsers)


const app = createApp(MyApp)

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(i18n)

app.config.productionTip = false
app.config.compilerOptions.whitespace = 'condense'

// Style
import '@/style/base.scss';
import '@/style/layout.scss';
import '@/style/typo.scss';
import '@/style/fonts.scss';


app.mount('#app')