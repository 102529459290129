<template>
  <svg version="1.1" class="arrow" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve" fill="currentColor">
    <path d="M17.7,8.4c1.4,0.8,1.9,2.7,1.1,4.1c-0.3,0.5-0.6,0.8-1.1,1.1l-10,5.8c-1.4,0.8-3.3,0.3-4.1-1.1c-0.3-0.5-0.4-1-0.4-1.5V5.2c0-1.7,1.3-3,3-3c0.5,0,1,0.1,1.5,0.4L17.7,8.4z"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconArrow',
    components: {},
    data: () => ({}),
    props: {},
    computed: {},
    methods: {}
  }
</script>