<template>
  <div class="view view-home">
    <HeaderBlock
      :localesOptionsVisibility="localesOptionsVisibility"
      @toggle-locales-switch="toggleLocalesSwitch()"
      @close-locales-switch="closeLocalesSwitch()"
      @open-popup-menu="openPopupMenu()" />
    <!--<Hero
        :title="$t('pages.home.special.title')"
        :body="$t('pages.home.special.body')"
        :ctas="[
          { label: $t('pages.home.special.link_1'), href: 'https://live.horses-and-competitions.com/fr/event/1208/', arrowIcon: true },
          { label: $t('pages.home.special.link_2'), href: 'https://live.horses-and-competitions.com/fr/event/1207/', arrowIcon: true },
          { label: $t('pages.home.special.link_3'), href: 'https://live.horses-and-competitions.com/fr/event/1206/', arrowIcon: true }
          ]"
        backgroundImage="hero-home.jpg"
        :popupMenuVisibility="popupMenuVisibility"
        @close-popup-menu="closePopupMenu()" />-->
    <Hero
      :title="$t('pages.home.title')"
      :body="$t('pages.home.body')"
      :ctas="[
          { label: $t('navbar.results'), href: link_result_dashboard, arrowIcon: true },
          { label: $t('navbar.circuits'), href: 'https://app.horses-and-competitions.com/circuit/', arrowIcon: true }
          ]"
      backgroundImage="hero-home.jpg"
      :popupMenuVisibility="popupMenuVisibility"
      @close-popup-menu="closePopupMenu()" />
    <Tabs
      :title="$t('pages.home.offer.title')"
      :body="$t('pages.home.offer.subtitle')"
      :tabs="[
        { 
          fontAwesomeIcon: 'phone-laptop',
          title: $t('pages.home.offer.tabs.ergonomie.title'),
          body: $t('pages.home.offer.tabs.ergonomie.body'),
          details: [
            { body: $t('pages.home.offer.tabs.ergonomie.details.1') },
            { body: $t('pages.home.offer.tabs.ergonomie.details.2') },
            { body: $t('pages.home.offer.tabs.ergonomie.details.3') }
          ],
        },{ 
          fontAwesomeIcon: 'books',
          title: $t('pages.home.offer.tabs.discipline.title'),
          body: $t('pages.home.offer.tabs.discipline.body'),
          details: [
            { body: $t('pages.home.offer.tabs.discipline.details.1') },
            { body: $t('pages.home.offer.tabs.discipline.details.2') },
            { body: $t('pages.home.offer.tabs.discipline.details.3') },
            { body: $t('pages.home.offer.tabs.discipline.details.4') },
            { body: $t('pages.home.offer.tabs.discipline.details.5') },
          ],
        },{ 
          fontAwesomeIcon: 'magic',
          title: $t('pages.home.offer.tabs.pratique.title'),
          body: $t('pages.home.offer.tabs.pratique.body'),
          details: [
            { body: $t('pages.home.offer.tabs.pratique.details.1') },
            { body: $t('pages.home.offer.tabs.pratique.details.2') },
            { body: $t('pages.home.offer.tabs.pratique.details.3') },
          ],
        },{ 
          fontAwesomeIcon: 'hand-holding-usd',
          title: $t('pages.home.offer.tabs.accessible.title'),
          body: $t('pages.home.offer.tabs.accessible.body'),
          details: [
            { body: $t('pages.home.offer.tabs.accessible.details.1') },
            { body: $t('pages.home.offer.tabs.accessible.details.2') },
            { body: $t('pages.home.offer.tabs.accessible.details.3') },
          ],
        },{ 
          fontAwesomeIcon: 'balance-scale',
          title: $t('pages.home.offer.tabs.conforme.title'),
          body: $t('pages.home.offer.tabs.conforme.body'),
          details: [
            { body: $t('pages.home.offer.tabs.conforme.details.1') },
            { body: $t('pages.home.offer.tabs.conforme.details.2') },
            { body: $t('pages.home.offer.tabs.conforme.details.3') },
          ],
        }]"
      :ctas="[
        { label: $t('pages.home.cta.link_subscribe'), href: link_subscription, arrowIcon: true, target: '_blank' }]"
     />
    <Plan
      :title="$t('pages.home.price.title')"
      :body="$t('pages.home.price.body')"
      :pricing="{
        title: $t('pages.home.price.pricing.title'),
        price: $t('pages.home.price.pricing.price'),
        label: $t('pages.home.price.pricing.label'),
        conditions: $t('pages.home.price.pricing.conditions'),
        cta: { label: $t('pages.home.cta.link_subscribe'), href: link_subscription, arrowIcon: true, target: '_blank' }
      }"
      :list="[
        { body: $t('pages.home.price.pricing.list.1') },
        { body: $t('pages.home.price.pricing.list.2') },
        { body: $t('pages.home.price.pricing.list.3') },
        { body: $t('pages.home.price.pricing.list.4') },
        { body: $t('pages.home.price.pricing.list.5') }
        ]"
      backgroundImage="pricing-home.jpg"
      :isFramed="true" />
    <Gallery
      :title="$t('pages.home.partners.title')"
      body=""
      :galleryItemsList="[
        { imageSrc: 'customers/logo-boulerie-jump.jpg', imageAlt: 'Boulerie Jump' },
        { imageSrc: 'customers/logo-pompadour.jpg', imageAlt: 'SCHP Pompadour' },
        { imageSrc: 'customers/logo-bellevue.jpg', imageAlt: 'Bellevue Merignac' },
        { imageSrc: 'customers/logo-pied-bercy.jpg', imageAlt: 'Pied Bercy' },
        { imageSrc: 'customers/logo-la-maison-du-poney.jpg', imageAlt: 'La Maison du Poney' },
        { imageSrc: 'customers/logo-centre-equestre-mios.jpg', imageAlt: 'Centre équestre Mios' },
        { imageSrc: 'customers/logo-saint-medard-jalles.jpg', imageAlt: 'Saint Medard en Jalles' },
        ]"
    />
    <FooterBlock />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

  import HeaderBlock from '@/components/sections/_Header.vue'
  import Hero from '@/components/sections/_Hero.vue'
  import Tabs from '@/components/sections/_Tabs.vue'
  import Plan from '@/components/sections/_Plan.vue'
  import Gallery from '@/components/sections/_Gallery.vue'
  import FooterBlock from '@/components/sections/_Footer.vue'

  export default defineComponent({
    name: 'Home',
    components: {
      HeaderBlock,
      Hero,
      Tabs,
      Plan,
      Gallery,
      FooterBlock
    },
    data: () => ({
      localesOptionsVisibility: false,
      popupMenuVisibility: false,
      link_subscription: process.env.VUE_APP_LINK_SUBSCRIPTION,
      link_result_dashboard: process.env.VUE_APP_LINK_RESULT_DASHBOARD
    }),
    props: {},
    computed: {},
    methods: {
      toggleLocalesSwitch(){
        this.localesOptionsVisibility = !this.localesOptionsVisibility;
      },
      closeLocalesSwitch(){
        this.localesOptionsVisibility = false;
      },
      openPopupMenu(){
        this.popupMenuVisibility = true;
      },
      closePopupMenu(){
        this.popupMenuVisibility = false;
      },
    },
    mounted() {
      document.title = 'Horses & Competitions';
      window.scrollTo(0, 0)
    }
  })
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .view-home{}
</style>
