import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../i18n'

import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import Team from '../views/Team.vue'
import Pricing from '../views/Pricing.vue'
import MentionsLegales from '../views/MentionsLegales.vue'


const routes = [
  {
    path: '/:locale/',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale;

      // get available locales
      const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)

      const supported_locales = []
      locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
          supported_locales.push(matched[1])
        }
      })

      if (!supported_locales.includes(locale)) return next(process.env.VUE_APP_I18N_FALLBACK_LOCALE + '/');
      if (i18n.mode === 'legacy') {
        i18n.global.locale = locale;
      } else {
        i18n.global.locale.value = locale;
      }

      document.querySelector('html').setAttribute('lang', locale);
      return next()
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'services',
        name: 'Services',
        component: Services
      },
      {
        path: 'team',
        name: 'Team',
        component: Team
      },
      {
        path: 'price',
        name: 'Pricing',
        component: Pricing
      },
      {
        path: 'mentions-legales',
        name: 'MentionsLegales',
        component: MentionsLegales
      },
    ]
  },
  {
    path: '/:catchAll(.*)*',
    redirect() {

      const localeBrowser = (navigator && navigator.language) || process.env.VUE_APP_I18N_FALLBACK_LOCALE;
      const localStr = localeBrowser.split('-');

      return localStr[0] + '/';
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
