<template>
  <section class="section-hero" :style="{'background-image': `url(${require('@/assets/images/' + backgroundImage)})`}">
    <div class="content-wrapper v-align">
      <div>
        <div class="intro-wrapper">
          <h1 v-if="title" v-html="title" />
          <p v-if="body" v-html="body" />
          <div class="ctas" v-if="ctas.length > 0">
            <a v-for="(cta, index) in ctas" v-bind:key="index" :href="cta.href" class="button">
              {{cta.label}} <IconArrow v-if="cta.arrowIcon" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <PopupMenu v-if="popupMenuVisibility" @close-popup-menu="closePopupMenu()" />
  </section>
</template>

<script>
  import IconArrow from '@/components/elements/_Arrow.vue'
  import PopupMenu from '@/components/sections/_PopupMenu.vue'

  export default {
    name: 'Hero',
    components: {
      IconArrow,
      PopupMenu,
    },
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
      ctas: {
        type: Array,
        default: () => [{ label: null, href: null, arrowIcon: null }]
      },
      backgroundImage: {
        type: String,
        default: null
      },
      popupMenuVisibility: {
        type: Boolean,
        default: false
      }
    },
    computed: {},
    methods: {
      closePopupMenu() {
        this.$emit('close-popup-menu')
      }
    }
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .section-hero{
    & > .content-wrapper{
      min-height: 470px;
      padding-top: 120px; // Make sure content won't sit below the menu
      text-align: left;
      @include screens-m {
        min-height: 470px;
        padding-top: 160px; 
      } 
      @include screens-l {
        min-height: 30vh;
        padding-top: 220px;
      }

      .intro-wrapper{
        padding-bottom: 0;
        
        p{
          font-size: 18px;
          @include screens-m { font-size: 20px; } 
          @include screens-l { font-size: 22px; }
        }

        .ctas{
          margin-top: 30px;
          @include screens-m { margin-top: 40px; } 
          @include screens-l { margin-top: 60px; }

          .button{
            text-transform: uppercase;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
</style>