<template>
  <div class="view view-legals">
    <HeaderBlock
      :localesOptionsVisibility="localesOptionsVisibility"
      @toggle-locales-switch="toggleLocalesSwitch()"
      @close-locales-switch="closeLocalesSwitch()"
      @open-popup-menu="openPopupMenu()" />
    <Hero
      :title="$t('pages.legals.title')"
      body=""
      :ctas="[]"
      backgroundImage="hero-pricing.jpg"
      :popupMenuVisibility="popupMenuVisibility"
      @close-popup-menu="closePopupMenu()" />
    <ContentBlock
      :title="$t('pages.legals.edition.titre')"
      :body="$t('pages.legals.edition.body')" />

    <ContentBlock
        :title="$t('pages.legals.publication.titre')"
        :body="$t('pages.legals.publication.body')" />

    <ContentBlock
        :title="$t('pages.legals.hosting.titre')"
        :body="$t('pages.legals.hosting.body')" />

    <ContentBlock
        :title="$t('pages.legals.access.titre')"
        :body="$t('pages.legals.access.body')" />

    <ContentBlock
        :title="$t('pages.legals.owner.titre')"
        :body="$t('pages.legals.owner.body')" />

    <ContentBlock
        :title="$t('pages.legals.privacy.titre')"
        :body="$t('pages.legals.privacy.body')" />

    <ContentBlock
        :title="$t('pages.legals.editing.titre')"
        :body="$t('pages.legals.editing.body')" />

    <ContentBlock
        :title="$t('pages.legals.data.titre')"
        :body="$t('pages.legals.data.body')" />

    <ContentBlock
        :title="$t('pages.legals.usage.titre')"
        :body="$t('pages.legals.usage.body')" />

    <ContentBlock
        :title="$t('pages.legals.who.titre')"
        :body="$t('pages.legals.who.body')" />

    <ContentBlock
        :title="$t('pages.legals.opposition.titre')"
        :body="$t('pages.legals.opposition.body')" />

    <ContentBlock
        :title="$t('pages.legals.profiling.titre')"
        :body="$t('pages.legals.profiling.body')" />

    <FooterBlock />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

  import HeaderBlock from '@/components/sections/_Header.vue'
  import Hero from '@/components/sections/_Hero.vue'
  import ContentBlock from '@/components/sections/_Content_legal.vue'
  import FooterBlock from '@/components/sections/_Footer.vue'

  export default defineComponent({
    name: 'Team',
    components: {
      HeaderBlock,
      Hero,
      ContentBlock,
      FooterBlock,
    },
    data: () => ({
      localesOptionsVisibility: false,
      popupMenuVisibility: false
    }),
    props: {},
    computed: {},
    methods: {
      toggleLocalesSwitch(){
        this.localesOptionsVisibility = !this.localesOptionsVisibility;
      },
      closeLocalesSwitch(){
        this.localesOptionsVisibility = false;
      },
      openPopupMenu(){
        this.popupMenuVisibility = true;
      },
      closePopupMenu(){
        this.popupMenuVisibility = false;
      },
    },
    mounted() {
      document.title = this.$t('pages.legals.title') + ' - Horses & Competitions';
      window.scrollTo(0, 0)
    }
  })
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .view-home{}
</style>
