<template>
  <section class="section-tabs">
    <div class="content-wrapper">
      <div class="intro-wrapper">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
      <div class="tabs-wrapper" v-if="tabs.length > 0">
        <div class="content-stretcher">
          <div class="tabs-switch-wrapper">
            <ul class="tabs-switch">
              <li v-for="(tab, indexTab) in tabs" v-bind:key="indexTab" @click='selectTab(indexTab)' :class="['button flat', indexTab === currentTabIndex ? 'active' : '']">{{tab.title}}</li>
            </ul>
          </div>
        </div>
        <ul class="tabs-details">
          <li v-for="(tab, indexTab) in tabs" v-bind:key="indexTab" v-show="indexTab === currentTabIndex">
            <div class="intro">
              <div class="icon">
                <font-awesome-icon :icon="['far', tab.fontAwesomeIcon]" />
              </div>
              <div class="description">
                <h3 v-html="tab.title" />
                <p v-html="tab.body" />
              </div>
            </div>
            <ul v-if="tab.details.length > 0">
              <li v-for="(detail, indexDetail) in tab.details" v-bind:key="indexDetail" v-html="detail.body" />
            </ul>
          </li>
        </ul>
      </div>
      <div class="ctas" v-if="ctas.length > 0">
        <a v-for="(cta, index) in ctas" v-bind:key="index" :href="cta.href" :target="cta.target" class="button">{{cta.label}} <IconArrow v-if="cta.arrowIcon" /></a>
      </div>
    </div>
  </section>
</template>

<script>
  import IconArrow from '@/components/elements/_Arrow.vue'

  export default {
    name: 'OfferServices',
    components: {
      IconArrow
    },
    data: () => ({
      currentTabIndex: 0 // The index of the active tab
    }),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
      tabs: {
        type: Array,
        default: () => [{ fontAwesomeIcon: null, title: null, body: null, details: [] }]
      },
      ctas: {
        type: Array,
        default: () => [{ label: null, href: null, arrowIcon: null }]
      },
    },
    computed: {},
    methods: {
      selectTab(i) {
        this.currentTabIndex = i
      }
    }
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .section-tabs{
    .content-wrapper{
      .tabs-wrapper{
        max-width: $content_max-width_m;
        margin: 0 auto;

        .content-stretcher{
          @include screens-s {
            overflow-y: visible;
            overflow-x: auto;
            margin-left: $content_padding_s * -1; // Strech element side to side
            margin-right: $content_padding_s * -1;

            // Hide native scrollbar but keep scrolling functionning
            -ms-overflow-style: none; // IE, Edge
            scrollbar-width: none; // Firefox
            &::-webkit-scrollbar{ 
              display: none;
              width: 0;
              height: 0;
              -webkit-appearance: none;
            } // Chrome, Safari and Opera
          }

          .tabs-switch-wrapper{
            @include screens-s {
              width: fit-content; // Make sure will will have some padding right to the last element
              padding-left: $content_padding_s;
              padding-right: $content_padding_s;
            }

            ul.tabs-switch{
              @include display-flex;
              align-items: flex-start; // Elements are aligned next to the top
              justify-content: flex-start; // Elements are aligned next to each other to the left
              @include screens-m { justify-content: center; } // Elements are centered
              @include screens-l { justify-content: center; }

              & > li.button{
                @include flex(none); // Make sure the specified width will be respected
                min-width: auto;
                text-transform: uppercase;
                color: $colors-blue-navy;
                background-color: $colors-grey-light;
                margin: 6px 0 12px 0; // To make sure drop shadow is visible (hover and active buttons)
                @include box-shadow(none);
                @include screens-m { @include flex(auto); } // Strech buttons to take all available space 
                @include screens-l { @include flex(auto); }
                
                &:not(:first-of-type){
                  margin-left: 10px;
                  @include screens-l {
                    margin-left: 15px;
                  }
                }

                &:hover,
                &.active{
                  color: white;
                  background-color: $colors-blue-navy;
                  @include box-shadow(0 2px 10px 0 rgba(18, 29, 79, 0.3));
                }
              }
            }
          }

          
        }

        ul.tabs-details{
          max-width: $content_max-width_m * 0.8;
          text-align: left;
          margin: 25px auto 0 auto;
          @include screens-m { margin-top: 45px; }
          @include screens-l { margin-top: 60px; }

          & > li{
            .intro{
              @include display-flex;
              align-items: center; // Center elements vertically
              justify-content: flex-start; // Elements are aligned next to each other to the left

              .icon{
                @include flex(none); // Make sure the specified width will be respected
                color: $colors-blue-navy;
                width: 60px;
                font-size: 60px;
                margin-right: 40px;
              }
              .description{
                h3{
                  text-transform: uppercase;
                }
              }
            }

            & > ul{
              margin-top: 30px;

              li{
                position: relative;
                text-transform: uppercase;
                padding-left: 32px;
                @include screens-m { padding-left: 35px; }
                @include screens-l { padding-left: 40px; }

                & + li{
                  margin-top: 10px; // Add space between two successive li
                }

                &:before{
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  @include disk(18px, $colors-blue-navy);
                  @include transform(translateY(10%)); // Make sure that the disk is perfectly aligned with the text
                  @include screens-m { @include disk(20px, $colors-blue-navy); }
                  @include screens-l { @include disk(22px, $colors-blue-navy); }
                }
              }
            }
          }
        }
      }
    }
  }
</style>