<template>
  <div class="locales-switch">
    <div class="button flat" @click="toggleLocalesSwitch">{{ $i18n.locale.toUpperCase() }} <IconArrow /></div>
    <ul class="options" v-if="localesOptionsVisibility">
      <li v-for="(lang, i) in langs" :key="`lang-${i}`" :class="lang.short === $i18n.locale ? 'active' : ''" @click="setLocale(lang.short)">
        {{ lang.long }}
      </li>
    </ul>
  </div>
</template>

<script>
  import IconArrow from '@/components/elements/_Arrow.vue'

  export default {
    name: 'LocalesSwitch',
    components: {
      IconArrow
    },
    data: () => ({
      langs: [
        {
          short: 'fr',
          long: 'Français'
        },
        {
          short: 'en',
          long: 'English'
        },
        {
          short: 'nl',
          long: 'Nederlands'
        }
      ]
    }),
    props: {
      localesOptionsVisibility: {
        type: Boolean,
        default: false
      }
    },
    computed: {},
    methods: {
      toggleLocalesSwitch() {
        this.$emit('toggle-locales-switch');
      },
      setLocale(locale) {
        this.$router.push({ name: this.$route.name, params: { locale: locale } });
        this.$i18n.locale = locale;
        this.$emit('close-locales-switch');
      }
    }
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .locales-switch{
    position: relative;

    .button{
      min-width: 0;
      padding-left: 20px;
      padding-right: 20px;

      svg{
        transform: rotate(90deg) translate(-2px, -3px);
      }
    }

    ul.options{
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 15px;
      background-color: white;
      @include box-shadow(0 2px 10px 0 rgba(18, 29, 79, 0.3));
      transform: translate(0, calc(100% + 10px));
      overflow: hidden;

      li{
        display: block;
        min-width: 130px;
        padding: 10px 15px 8px 15px;
        text-align: left;
        cursor: pointer;

        &:not(:first-child){
          border-top: 1px solid #F7F7F7;
        }
      
        &:hover{
          background-color: #F7F7F7;
        }
      }
    }
  }
</style>