<template>
  <div class="popup-menu">
    <div class="popup-menu-switch close" @click="closePopupMenu">
      <font-awesome-icon :icon="['far', 'times']" />
    </div>
    <div class="content-wrapper">
      <MenuSite />
      <MenuCompetitions />
    </div>
  </div>
</template>

<script>
  import MenuSite from '@/components/elements/_MenuSite.vue'
  import MenuCompetitions from '@/components/elements/_MenuCompetitions.vue'

  export default {
    name: 'PopupMenu',
    components: {
      MenuSite,
      MenuCompetitions
    },
    data: () => ({}),
    props: {},
    computed: {},
    methods: {
      closePopupMenu() {
        this.$emit('close-popup-menu')
      }
    }
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .popup-menu{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 414px;
    height: 100%;
    background-color: rgba(22, 30, 70, 0.95);
    z-index: 2; // Make sure it will sit above any another section

    .popup-menu-switch{
      position: absolute;
      top: 37px; // Match the position of the hamburger icon below
      right: $content_padding_s; // Match the position of the hamburger icon below
      color: white;
      font-size: 25px;
      @include transform(translateX(-20%)); // Center horizontally to match the exact position of the hamburger icon of the header
      @include screens-m { 
        top: 64px;
        right: $content_padding_m; 
      }
      @include screens-l { 
        top: 88px;
        right: $content_padding_l; 
      }

      &:hover{
        cursor: pointer;
      }
    }

    .content-wrapper{
      .menu{
        margin-top: $content_padding_s;

        h3{
          font-size: 20px;
          color: white;
          text-align: left;
        }

        ul.row{
          @include flex-direction(column); // Stack elements below each other
          align-items: flex-start; // Elements are aligned next to the top

          &:not(:first-of-type){
            padding-top: 6px;
            margin-top: 6px;
            border-top: 1px solid white;
            @include screens-l {
              padding-top: 16px;
              margin-top: 16px;
            }
          }

          li.column{
            display: block;
            width: 100%;
            margin: 3px 0;
            text-align: left;
            @include screens-l { margin: 8px 0; }

            a{
              display: block;
              width: 100%;
              text-align: left;
              padding: 6px 12px;
              color: rgba(255, 255, 255, 0.6);
              border-radius: 100px;
              @include screens-l { padding: 8px 16px; }

              &:hover{
                text-decoration: none; 
              }

              &.router-link-exact-active{
                color: white;
                background-color: rgba(255, 255, 255, 0.3);
              }

              &.button{
                min-width: 0;
                padding: 10px;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
</style>