<template>
  <div class="menu site">
    <h3>{{ $t('footer.link.title-site') }}</h3>
    <ul class="row">
      <li class="column">
        <router-link :to="{name: 'Home', params: { locale: this.$i18n.locale }}">{{ $t('navbar.home') }}</router-link>
      </li>
      <li class="column">
        <router-link :to="{name: 'Services', params: { locale: this.$i18n.locale }}">{{ $t('navbar.services') }}</router-link>
      </li>
      <li class="column">
        <router-link :to="{name: 'Team', params: { locale: this.$i18n.locale }}">{{ $t('navbar.team') }}</router-link>
      </li>
      <li class="column">
        <router-link :to="{name: 'Pricing', params: { locale: this.$i18n.locale }}">{{ $t('navbar.price') }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'MenuSite',
    components: {},
    data: () => ({}),
    props: {},
    computed: {},
    methods: {}
  }
</script>