<template>
  <div class="menu competitions">
    <h3>{{ $t('footer.link.title-competitions') }}</h3>
    <ul class="row">
      <li class="column">
        <a :href="link_login">{{ $t('navbar.login') }}</a>
      </li>
      <li class="column">
        <a :href="link_subscription">{{ $t('navbar.subscribe') }}</a>
      </li>
      <li class="column">
        <a :href="link_result_dashboard">{{ $t('navbar.results') }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'MenuCompetitions',
    components: {},
    data: () => ({
      link_login: process.env.VUE_APP_LINK_LOGIN,
      link_subscription: process.env.VUE_APP_LINK_SUBSCRIPTION,
      link_result_dashboard: process.env.VUE_APP_LINK_RESULT_DASHBOARD,
    }),
    props: {},
    computed: {},
    methods: {}
  }
</script>