<template>
  <div class="view view-services">
    <HeaderBlock
      :localesOptionsVisibility="localesOptionsVisibility"
      @toggle-locales-switch="toggleLocalesSwitch()"
      @close-locales-switch="closeLocalesSwitch()"
      @open-popup-menu="openPopupMenu()" />
    <Hero
      :title="$t('pages.services.title')"
      :body="$t('pages.services.subtitle')"
      :ctas="[]"
      backgroundImage="hero-services.jpg"
      :popupMenuVisibility="popupMenuVisibility"
      @close-popup-menu="closePopupMenu()" />

    <FeatureDetails
        :title="$t('pages.services.autonomous.title')"
        :body="$t('pages.services.autonomous.body')"
        :feature="{
        imageSrc: 'screenshot-epreuves.jpg',
        imageAlt: 'Liste des épreuves',
        detailsList: [
          { body: $t('pages.services.autonomous.list.1') },
          { body: $t('pages.services.autonomous.list.2') },
          { body: $t('pages.services.autonomous.list.3') },
          { body: $t('pages.services.autonomous.list.4') },
          { body: $t('pages.services.autonomous.list.5') },
          { body: $t('pages.services.autonomous.list.6') },
        ]
      }"
        :ctas="[
        { label: $t('pages.services.cta.link_subscribe'), href: link_subscription, arrowIcon: true, target: '_blank' }]" />

    <FeatureDetails 
      :title="$t('pages.services.gestion.title')"
      :body="$t('pages.services.gestion.body')"
      :feature="{
        imageSrc: 'screenshot-juger.jpg',
        imageAlt: 'Juger jumping',
        detailsList: [
          { body: $t('pages.services.gestion.list.1') },
          { body: $t('pages.services.gestion.list.2') },
          { body: $t('pages.services.gestion.list.3') },
          { body: $t('pages.services.gestion.list.4') },
          { body: $t('pages.services.gestion.list.5') },
        ]
      }"
      :ctas="[]" />
    <FeatureGallery
        :title="$t('pages.services.challenge.title')"
        :body="$t('pages.services.challenge.body')"
      :images="[]"
      :ctas="[
        { label: $t('pages.services.cta.link_subscribe'), href: link_subscription, arrowIcon: true, target: '_blank' }]" />
    <FooterBlock />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

  import HeaderBlock from '@/components/sections/_Header.vue'
  import Hero from '@/components/sections/_Hero.vue'
  import FeatureDetails from '@/components/sections/_FeatureDetails.vue'
  import FeatureGallery from '@/components/sections/_FeatureGallery.vue'
  import FooterBlock from '@/components/sections/_Footer.vue'

  export default defineComponent({
    name: 'Home',
    components: {
      HeaderBlock,
      Hero,
      FeatureDetails,
      FeatureGallery,
      FooterBlock,
    },
    data: () => ({
      localesOptionsVisibility: false,
      popupMenuVisibility: false,
      link_subscription: process.env.VUE_APP_LINK_SUBSCRIPTION
    }),
    props: {},
    computed: {},
    methods: {
      toggleLocalesSwitch(){
        this.localesOptionsVisibility = !this.localesOptionsVisibility;
      },
      closeLocalesSwitch(){
        this.localesOptionsVisibility = false;
      },
      openPopupMenu(){
        this.popupMenuVisibility = true;
      },
      closePopupMenu(){
        this.popupMenuVisibility = false;
      },
    },
    mounted() {
      document.title = this.$t('pages.services.title') + ' - Horses & Competitions';
      window.scrollTo(0, 0)
    }
  })
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .view-services{}
</style>
