<template>
  <section class="section-feature-gallery">
    <div class="content-wrapper">
      <div class="intro-wrapper flat">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
      <div class="row" v-if="images.length > 0">
        <div class="column half">
          <img class="bevel" :src="require('@/assets/images/'+images[0].src)" :alt="images[0].alt" />
        </div>
        <div class="column half">
          <img class="bevel" :src="require('@/assets/images/'+images[1].src)" :alt="images[1].alt" />
        </div>
      </div>
      <div class="ctas" v-if="ctas.length > 0">
        <a v-for="(cta, index) in ctas" v-bind:key="index" :href="cta.href" :target="cta.target" class="button">{{cta.label}} <IconArrow v-if="cta.arrowIcon" /></a>
      </div>
    </div>
  </section>
</template>

<script>
  import IconArrow from '@/components/elements/_Arrow.vue'

  export default {
    name: 'FeatureGallery',
    components: {
      IconArrow
    },
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
      images: {
        type: Array,
        default: () => [{ src: null, alt: null }]
      },
      ctas: {
        type: Array,
        default: () => [{ label: null, href: null, arrowIcon: null }]
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .section-feature-gallery{
    .content-wrapper{
      max-width: $content_max-width_l;
      margin: 0 auto;
    }
  }
</style>