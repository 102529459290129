<template>
  <div class="menu informations">
    <h3>{{ $t('footer.link.title-informations') }}</h3>
    <ul class="row">
      <li class="column">
        <a :href="link_support_status" target="_blank">{{ $t('footer.link.status') }}</a>
      </li>
      <li class="column">
        <a :href="link_support_documentation" target="_blank">{{ $t('footer.link.documentation') }}</a>
      </li>
      <li class="column">
        <router-link :to="{name: 'MentionsLegales', params: { locale: this.$i18n.locale }}">{{ $t('footer.link.mentions-legales') }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'MenuInformations',
    components: {},
    data: () => ({
      link_support_status: process.env.VUE_APP_LINK_SUPPORT_STATUS,
      link_support_documentation: process.env.VUE_APP_LINK_SUPPORT_DOCUMENTATION
    }),
    props: {},
    computed: {},
    methods: {}
  }
</script>