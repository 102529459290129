<template>
  <section class="section-content">
    <div class="content-wrapper">
      <div class="intro-wrapper">
        <h3 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'ContentBlock',
    components: {},
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .section-content{
    .content-wrapper{
      .intro-wrapper{
        padding-bottom: 0;
      }
    }
  }

  .section-content{
    .content-wrapper{
      .intro-wrapper{
        h3 {
          text-align: left;
        }
      }
    }
  }

  .section-content{
    .content-wrapper{
      .intro-wrapper{
        p {
          text-align: justify;
        }
      }
    }
  }

  .section-content{
    .content-wrapper{
      padding: 20px;
    }
  }
</style>