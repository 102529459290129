<template>
  <footer>
    <div class="content-wrapper">
      <div class="row">
        <div class="column quarter">
          <h3 v-html="$t('pages.home.title')" />
          <p v-html="$t('pages.home.body')" />
          <Contact />
        </div>
        <div class="column quarter">
          <MenuSite />
        </div>
        <div class="column quarter">
          <MenuCompetitions />
        </div>
        <div class="column quarter">
          <MenuInformations />
        </div>
      </div>
      <div class="copyright">
        <Logo />
        <p class="note">© Copyrights 2024 Horses &amp; Competitions</p>
      </div>
    </div>
  </footer>
</template>

<script>
  import Logo from '@/components/elements/_Logo.vue'
  import Contact from '@/components/elements/_Contact.vue'
  import MenuSite from '@/components/elements/_MenuSite.vue'
  import MenuCompetitions from '@/components/elements/_MenuCompetitions.vue'
  import MenuInformations from '@/components/elements/_MenuInformations.vue'

  export default {
    name: 'HeaderBlock',
    components: {
      Logo,
      Contact,
      MenuSite,
      MenuCompetitions,
      MenuInformations
    },
    data: () => ({}),
    props: {},
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  footer{
    background-color: $colors-blue-navy;

    .content-wrapper{
      text-align: left;

      h3, p, a{
        color: white;
      }

      & > .row{
        align-items: flex-start; // Elements are aligned next to the top
        justify-content: space-between; // Elements are spread out so that the space between them is the same
        max-width: $content_max-width_l;
        margin: 0 auto;
        @include screens-m { @include flex-wrap(wrap); } // Stack elements below each other if there is not enough space on one line

        & > .column{
          &:nth-child(1){
            @include flex(none); // Make sure the specified width will be respected
            @include screens-m {
              width: 100%;
              padding-right: 0;
              padding-bottom: $content_padding_m;
            }
            @include screens-l { width: 35%; }
          }
          &:nth-child(2){
            @include screens-m { 
              padding-left: 0;
            }
          }
          &:not(:nth-child(1)){
            @include screens-m { 
              width: 33.33%; 
            }
          }

          h3{
            font-size: 22px;
            margin-bottom: 20px;
          }

          p, ul li a{
            font-size: 17px;
          }

          .contact{
            margin-top: 20px;
          }

          ul{
            @include flex-direction(column); // Stack elements below each other
            align-items: flex-start; // Elements are aligned next to the top
            justify-content: flex-start; // Align elements to the left

            li + li{
              margin-top: 6px; // Add space between two successive li
              @include screens-m { margin-top: 8px; }
              @include screens-l { margin-top: 10px; }
            }
          }
        }
      }

      .copyright{
        text-align: center;
        margin-top: $content_padding_s;
        @include screens-m { margin-top: $content_padding_m; }
        @include screens-l { margin-top: $content_padding_l; }

        .logo{
          width: 60px;
          margin: 50px auto 15px auto;
          color: white; // Fill logo with white
        }
      }
    }
  }
</style>