<template>
  <section class="section-map">
    <div class="content-wrapper">
      <div class="intro-wrapper flat">
        <h2 v-if="title" v-html="title" />
        <p v-if="body" v-html="body" />
      </div>
      <div class="map-wrapper">
        <img class="bevel" :src="require('@/assets/images/map.jpg')" :alt="title" />
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'MapBlock',
    components: {},
    data: () => ({}),
    props: {
      title: {
        type: String,
        default: null
      },
      body: {
        type: String,
        default: null
      },
    },
    computed: {},
    methods: {}
  }
</script>

<style lang="scss">
  @import "@/style/_mixins.scss";
  @import "@/style/_variables.scss";

  .section-map{
    .content-wrapper{
      .map-wrapper{
        max-width: $content_max-width_m;
        margin: 0 auto;
      }
    }
  }
</style>